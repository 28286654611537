import { graphql, useStaticQuery } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import './Legal.scss'

const Legal = () => {
  const {
    mdx: {
      body,
    },
  } = useStaticQuery(graphql`
    query LegalQuery {
      mdx(fields: { title: { eq: "LegalIndex" } } ) {
        body
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Legal"
      />
      <div id="legal-page">
        <MDXRenderer>{body}</MDXRenderer>
      </div>
    </Layout>
  )
}

export default Legal